import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductUnitCard = ({data, index})=>{
    return (
        <div className={`grid grid-cols-12 w-full h-full p-8 gap-8 ${index %2 == 0 ? 'bg-primary-light' : ''} md:bg-primary-light md:border md:border-primary-gold sm:gap-0 sm:p-2`}>
            <div className={`col-span-6 ${index % 2 == 0 ? 'order-1' : '' } md:col-span-12 md:-order-none`}>
                <img src={data.unitImage} alt="" className="w-full h-full object-cover md:!h-48" />
            </div>
            <div className="col-span-6 p-4 md:col-span-12">
                    <div className="flex justify-between items-center mb-4 xl:flex-col xl:items-start">
                        <h6 className="text-6xl text-secondary-gold mb-4 md:text-5xl sm:!text-4xl">{data.unitTitle}</h6>
                        <div className="flex flex-col gap-2">
                                <span className="text-xl text-amber-900">{data.marketPrice}<sup className="text-sm text-slate-400 ml-2">Market Price</sup></span>
                                <span className="text-4xl font-semibold text-red-400  sm:text-2xl">{data.earlyPrice}<sup className="text-sm text-slate-400 ml-2">2024 Early Price</sup></span>
                        </div>
                    </div>

                    <p className="text-md text-secondary-dark leading-tight tracking-wide mb-4">{data.unitDesc}</p>
                    <div className="grid grid-cols-3 gap-4 lg:gap-0 sm:grid-cols-1 sm:!gap-2">
                        <div className="flex flex-col justify-center border-r-2 border-primary-gold lg:px-2  sm:!border-b-2 sm:border-x-0">
                            <span className="text-secondary-dark font-bold uppercase text-md lg:text-sm ">Luas Tanah</span>
                            <span className="block text-primary-gold text-xl font-bold lg:text-lg">{data.luasTanah}m<sup>2</sup></span>
                        </div>
                        <div className="flex flex-col justify-center px-2 border-r-2 border-primary-gold lg:px-2 sm:!border-b-2 sm:border-x-0">
                            <span className="text-secondary-dark font-bold uppercase text-md lg:text-sm">Luas Bangunan</span>
                            <span className="block text-primary-gold text-xl font-bold lg:text-lg">{data.luasBangunan}m<sup>2</sup></span>
                        </div>
                        <div className="flex flex-col justify-center lg:px-2 sm:!border-b-2 sm:border-x-0 sm:border-primary-gold">
                            <span className="text-primary-gold font-bold uppercase text-2xl lg:text-xl">{data.ukuran}</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-start mt-4 gap-8">
                        {data.Facility.map((f,i)=>(<div className="flex items-center" key={i}>
                            <FontAwesomeIcon icon={f.icon} className="text-2xl mr-2 bg-secondary-gold p-2 rounded-full text-primary-light"/>
                            <span className="text-secondary-dark font-semibold">{f.title}</span>
                        </div>))}
                    </div>
                    <div className="mt-6 w-full">
                        <a href="https://wa.me/+6281286163385">
                            <button className="border-non px-4 py-1 bg-green-400 text-primary-light hover:bg-green-600 transition duration-150 border border-primary-gold md:w-full">Contact Us</button>
                        </a>
                    </div>
            </div>
        </div>        
    );
}

export default ProductUnitCard;