import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar.component";
import Footer from "../components/Footer.component";
import WhatsappFixed from "../components/buttons/WhatsappFixed.component";


const RootLayout = ()=>{
    return (
        <>
            <Navbar/>
            <main className="w-full h-full">
                <Outlet/>
            </main>
            <WhatsappFixed/>
            <Footer/>
        </>
    )
}

export default RootLayout;