import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FacilityIcon = ({icon, title})=>{
    return(
        <div className="flex flex-col items-center w-1/4 mb-16 sm:w-1/2">
            <div className="text-secondary-gold border border-secondary-gold rounded-md w-20 h-20 grid place-content-center mb-4">
                <FontAwesomeIcon icon={icon} className="text-4xl"/>
            </div>
            <span className="text-secondary-dark font-semibold text-lg text-center">{title}</span>
        </div>
    )
}

export default FacilityIcon;