import WhatsAppButton from "../components/buttons/WhatsappButton.component";
import LandingImage from "../components/LandingImage.component";
import houseOne from '../assets/rumah1.png';
import houseTwo from '../assets/rumah2.png';
import houseThree from '../assets/rumah3.png';

import About from "../components/About.component";
import Facility from "../components/Facility.component";
import Product from "../components/Product.component";
import Location from "../components/Location.component";
import Contact from "../components/Contact.component";

import SectionImage from "../components/SectionImage.component";
import FirstSectionImage from '../assets/section-1.png';
import SecondSectionImage from '../assets/section-2.png';

import ImageSlider from "../components/ImageSlider.component";
import sliderOne from '../assets/slider-1.png';
import sliderTwo from '../assets/slider-2.png';
import sliderThree from '../assets/slider-3.png';
import sliderFour from '../assets/slider-4.png';
import sliderFive from '../assets/slider-5.png';
import sliderSix from '../assets/slider-6.png';

const Home = ()=>{
    const dataLandingImage = [
        {
            src: houseOne,
            id: 'house1'
        },
        {
            src: houseTwo,
            id: 'house2'
        },
        {
            src: houseThree,
            id: 'house3'
        }
    ];

    const dataSlider = [
        {
            src: sliderOne
        },
        {
            src: sliderTwo
        },
        {
            src: sliderThree
        },
        {
            src: sliderFour
        },
        {
            src: sliderFive
        },
        {
            src: sliderSix
        },
        {
            src: sliderOne
        },
    ];

    return (
        <>
            <header className="bg-primary-dark primary-padding text-primary-light w-full">
                <div className="grid grid-cols-3 md:grid-cols-1 md:text-center">
                    <h1 className="col-span-2 text-5xl uppercase font-bold leading-normal sm:text-4xl">Rumah Millennials <br/> Nempel Garding Serpong</h1>
                    <div className="col-span-1 md:flex md:items-center md:flex-col">
                        <p className="mb-4 leading-relaxed">Park Serpong adalah kawasan yang akan dibangun menjadi kota mandiri dengan luas 400 hektar. Park Serpong dideveloped oleh salah satu developer besar yaitu Lippo Group</p>
                        <WhatsAppButton/>
                    </div>
                </div>
                <div className="grid py-6 gap-4" id="landingGrid">
                    {dataLandingImage.map((data)=>(<LandingImage src={data.src} id={data.id} key={data.id}/>))}
                </div>
                <div className="grid grid-cols-12">
                    <div className="col-span-8 sm:col-span-12 sm:mb-6 sm:text-center">
                        <span className="text-4xl font-bold text-primary-gold after:contents-['*'] relative after:block after:w-1/2 after:bg-primary-gold after:h-1 after:right-1 after:bottom-[-10px] after:absolute sm:after:right-0 sm:after:left-0 sm:after:m-auto">Special Launching</span>
                    </div>
                    <div className="col-span-4 flex sm:col-span-12">
                        <div className="flex-1 font-bold flex flex-col text-xl text-center">
                            <span className="text-primary-light">Starts From</span>
                            <span className="text-primary-gold">Rp 339 Juta</span>
                        </div>
                        <div className="flex-1 font-bold flex flex-col text-xl text-center">
                            <span className="text-primary-light">Cicilan</span>
                            <span className="text-primary-gold">Rp 2 Juta</span>
                        </div>
                    </div>
                </div>
            </header>
            <About/>
            <SectionImage heightImage='h-[400px] sm:h-[300px]'  src={FirstSectionImage}>
                <div className="absolute inset-0 m-auto flex justify-center items-center">
                    <h6 className="text-[8rem] font-bold text-secondary-gold opacity-70 sm:text-[5rem]">400Ha</h6>
                </div>
            </SectionImage>
            <Facility/>
            <Product/>
            <section className="w-full h-[550px] lg:h-[400px] sm:!h-[250px]">
                <ImageSlider slider={dataSlider}/>
            </section>
            <Location/>
            <Contact/>
        </>
    );
}


export default Home;