const SectionImage = ({src, heightImage, children, opacity})=>{
    return(
        <div className="relative">
            <img src={src} alt="" className={`w-full ${heightImage || ''}`}/>
            <div className={`overlay-black z-10 ${opacity || 'opacity-30'}`}></div>
            {children}
        </div>
    )
}

export default SectionImage;