import TitlePage from "./TitlePage.component";
import FacilityIcon from "./FacilityIcon.component";
import { faHouseChimney,faBuilding, faChargingStation, faBicycle, faBoltLightning, faRunning, faRecycle } from "@fortawesome/free-solid-svg-icons";

const Facility = () => {
    const dataFacility = [
        {   
            icon:faHouseChimney,
            title: 'Underground Infrastructure',
        },
        {   
            icon:faChargingStation,
            title: 'EV Charging Station',
        },
        {   
            icon:faBicycle,
            title: 'Bicycle Track',
        },
        {   
            icon:faBoltLightning,
            title: 'Green Energy',
        },
        {   
            icon:faBuilding,
            title: 'Town Management Division Apps',
        },
        {   
            icon:faRunning,
            title: 'Jogging Track',
        },
        {   
            icon:faRecycle,
            title: 'Recycling Unit',
        },
    ]
    
    return (
        <section className="bg-primary-light primary-padding py-10">
            <TitlePage title='Fasilitas Perumahan' subTitle='Fasilitas yang nantinya akan disediakan oleh Park Serpong kepada CityZen adalah:' position='center'/>
            <div className="flex flex-wrap justify-center mt-4">
                {dataFacility.map((data)=><FacilityIcon icon={data.icon} title={data.title} key={data.title}/>)}
            </div>
        </section>
    );
}

export default Facility;