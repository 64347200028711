const ProductCard = ({data:{image, productTitle, productDesc, earlyPrice, marketPrice, productType}})=>{
    return(
        <div>
            <div className="relative mb-4">
                <img src={image} alt="" className="h-52 w-full object-cover"/>
                <span className="absolute bg-primary-dark text-secondary-gold text-xl top-0 left-0 px-4 py-2 font-semibold opacity-80">{productTitle}</span>
            </div>
            <div className="flex mb-2">
                <div className="border-r border-primary-gold leading-none flex-1">
                    <span className="text-primary-dark font-bold text-sm">2024 Early Bird Special</span>
                    <span className="text-primary-gold font-bold text-lg block">Rp {earlyPrice}</span>
                </div>
                <div className="leading-none flex-1 px-2">
                    <span className="text-primary-dark font-bold text-sm">Market Price</span>
                    <span className="text-primary-gold font-bold text-lg block">Rp {marketPrice}</span>
                </div>
            </div>
            <div className="grid grid-cols-4">
                {productType.map((item, i) => (<span key={i} className="text-secondary-dark flex items-center font-semibold before:contents-['*'] before:block before:w-2 before:h-2 before:rounded-full before:bg-secondary-gold before:mr-2">{item}</span>))}
            </div> 
            <p className="leading text-sm py-2 text-secondary-dark text-justify h-32 overflow-clip">
                {productDesc}
            </p>
            <div className="flex justify-end">
                <a href="https://wa.me/+6281286163385">
                    <button className="border-non px-4 bg-green-400 text-primary-light hover:bg-green-600 transition duration-150">Contact Us</button>
                </a>
            </div>
        </div>
    );
}

export default ProductCard;