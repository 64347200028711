import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhatsAppButton = ()=>{
    return(
        <a href="https://wa.me/+6281286163385" target='_blank' className="bg-green-700 w-48 px-2 py-2 text-xl rounded-full font-bold cursor-pointer hover:opacity-80 transition duration-150 text-primary-light block text-center">
            <FontAwesomeIcon icon={faWhatsapp} className='mr-2'/>
            <span>Contact Us</span>
        </a>
    );
} 

export default WhatsAppButton;