import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhatsappFixed = ()=>{
    return(
        <a href="https://wa.me/+6281286163385" target='_blank' className="bg-green-600 text-2xl p-4 rounded-full font-bold cursor-pointer  transition duration-150 text-primary-light flex justify-center items-center fixed right-6 bottom-6 animate-bounce">
            <FontAwesomeIcon icon={faWhatsapp}/>
        </a>
    );
}

export default WhatsappFixed;