import { useState, useEffect, useRef } from "react";

const ImageSlider = ({slider})=>{
    const [current, setCurrent] = useState(0);
    const length = slider.length;
    const timeout = useRef(null);

    useEffect(() => {
        const nextSlide = () => {
            setCurrent((current) => (current === 5 ? 0 : current + 1));
        };

        timeout.current = setTimeout(nextSlide, 4000);

        return function () {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        };
    }, [current]);

    return (
        <div className="overflow-hidden w-full h-full">
            <div className={`flex w-full h-full transition ease-out duration-500` } style={{ transform: `translate3d(${-current * 100}%, 0, 0)` }}>
                {slider.map((data,i )=> <img src={data.src} className="w-full h-full" alt="" key={i} />)}
            </div>
        </div>
    );
}

export default ImageSlider;