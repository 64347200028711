const TitlePage = ({title, subTitle, position, optClass})=>{
    const positionTitle = {
        center: 'text-center',
        start: 'text-start'
    }

    const positionLine = {
        center: 'after:right-0 after:m-auto'
    }

    const positionSubTitle = {
        center: 'w-3/4'
    }
    
    return(
        <div className={`${positionTitle[position] || 'text-justify'}`}>
            <h2 className="text-primary-dark text-4xl font-bold mb-4"><span className={`relative after:contents-['*'] after:block after:h-[3px] after:bg-primary-gold after:absolute after:w-1/2 after:bottom-[-10px] after:left-0 ${positionLine[position] || ''} ${optClass || ''}`}>{title}</span></h2>
            <p className={`${positionSubTitle[position] || 'w-full'} text-lg leading-relaxed text-secondary-gray font-semibold m-auto`}>
                {subTitle}
            </p>
        </div>

    );
}

export default TitlePage;