import { useEffect } from "react";

import TitlePage from "./TitlePage.component";
import LokasiParkSerpong from '../assets/lokasi-park-serpong.png'
import { faMapLocationDot, faHouse, faCoins } from "@fortawesome/free-solid-svg-icons";
import BenenfitCard from "./BenefitCard.component";

const About = ()=>{
    const benefits = [
        {
            icon:faMapLocationDot,
            title: 'Lokasi Strategis',
            desc: 'Berada di tengah kota Serpong yang punya fasilitas dan infrastruktur modern lengkap.'
        },
        {
        icon:faHouse,
            title: 'Smart City',
            desc: 'Perumahan yang mendukung gaya hidup ramah lingkungan yang diselaraskan dengan penggunaan teknologi canggih.'
        },
        {
            icon:faCoins,
            title: 'Cicilan Ringan',
            desc: 'Pilihan unit rumah beragam dan premium dengan cicilan ekonomi, cocok untuk keluarga Gen-Z.'
        },
    ]
    return(
        <section className="bg-primary-light primary-padding py-10" id="about">
            <TitlePage title='Why Park Serpong' subTitle='Nikmati kemudahan kepemilikan rumah yang berlokasi strategis di pusat serpong yang terkoneksi langsung ke berbagai fasilitas umum sekitarnya. Banyak pilihan unit dengan harga terjangkau.' position='center'/>
            <div className="grid grid-cols-2 gap-8 my-10 lg:grid-cols-1 lg:text-center">
                <div className="flex items-center justify-center">
                    <img src={LokasiParkSerpong} alt="lokasi park serpong" className="w-full" />
                </div>
                <div className="text-lg leading-relaxed text-secondary-dark font-semibold flex justify-center flex-col tracking-wider">
                    <p className="mb-4">Park Serpong merupakan pilihan yang menarik karena lokasinya yang strategis, harga yang terjangkau, kualitas yang baik, dan akses yang mudah. Terletak di area yang strategis, kompleks ini memberikan kemudahan akses ke berbagai fasilitas dan transportasi umum. </p>
                    <p>Meskipun harga yang ditawarkan relatif terjangkau, kualitas bangunan dan fasilitas di dalamnya tetap terjaga dengan baik. Dengan kombinasi lokasi yang strategis, harga yang terjangkau, kualitas yang baik, serta akses yang mudah..</p>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-16 pt-8 lg:grid-cols-2 md:flex md:flex-col">
                {benefits.map((data)=><BenenfitCard icon={data.icon} title={data.title} desc={data.desc} key={data.title}/>)}
            </div>
        </section>
    );
}

export default About;