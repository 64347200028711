import { Link } from "react-router-dom";
import imageLogo from '../assets/logo.png';

const Footer = ()=> {
    return (
        <footer className="w-full bg-primary-dark">
            <div className="flex justify-center items-center flex-col p-5">
                <img src={imageLogo} alt="" className='w-64 mb-3'/>
                <ul className="w-1/4  flex justify-center items-center text-md text-secondary-gold gap-8  font-bold mb-1">
                    <li>
                        <Link to="/">Home</Link>
                    </li>   
                    <li>
                        <Link to="/products">Products</Link>
                    </li>
                    <li>
                        <a href="#about">About</a>
                    </li>
                    <li>
                        <a href="#contact">Contacts</a>
                    </li>
                </ul>
            </div>
            <div className="border-t border-primary-light text-primary-light text-center font-bold p-2">
                &copy; Copyright 2024 | Park Serpong
            </div>
        </footer>
    );
}

export default Footer;