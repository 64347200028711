import TitlePage from "./TitlePage.component";
import houseTwo from '../assets/rumah2.png'
import LocationIcon from "./LocationIcon.component";

const Location = ()=>{

    const dataLocation = [
        {
            time:'5Menit',
            location: 'Gading Serpong'
        },
        {
            time:'8Menit',
            location: 'Universitas Mulitmedia Nusantara'
        },
        {
            time:'10Menit',
            location: 'Karawaci'
        },
        {
            time:'10Menit',
            location: 'BSD'
        },
        {
            time:'10Menit',
            location: 'Universitas Pelita Harapan'
        },
    ]

    return (
        <section className="bg-primary-light primary-padding py-10 md:py-4">
            <div className="grid grid-cols-12 gap-12 sm:gap-0">
                <div className="col-span-5 md:col-span-12 md:mb-4">
                    <img src={houseTwo} alt="" className="w-full h-full object-cover" />
                </div>
                <div className="col-span-7 md:col-span-12">
                    <TitlePage title='Lokasi Park Serpong' position='start'/>
                    <p className={`w-full text-lg leading-relaxed text-secondary-gray font-semibold m-auto my-4 md:text-md`}>
                        PARK SERPONG terletak di kawasan seluas 400 Hektar yang nantinya akan dikelola menjadi kota mandiri yang lengkap dgn berbagai fasilitas.
                     </p>
                    <p className={`w-full text-lg leading-relaxed text-secondary-gray font-semibold m-auto mb-4`}>
                        Lokasinya sendiri persis di pinggir Jalan Raya Legok - Karawaci dan nempel sekali dengan Gading Serpong. Hanya 1 KM ke Cluster Baroni Summarecon Serpong.
                     </p>
                    <p className={`w-full text-lg leading-relaxed text-secondary-gray font-semibold m-auto`}>
                        Berikut titik di Google Maps nya : <br/>
                       <a href="https://maps.app.goo.gl/FStgbyKtugDLzpt58?g_st=iwb" className="text-blue-400 hover:text-blue-600 transition duration-300 break-all" target="_blank">https://maps.app.goo.gl/FStgbyKtugDLzpt58?g_st=iwb</a> 
                     </p>
                    <div className="grid grid-cols-2 gap-8 my-8 md:my-4">
                        {dataLocation.map((data,i)=><LocationIcon time={data.time} location={data.location} key={i}/>)}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Location;