import {useState} from 'react';
import SectionImage from '../components/SectionImage.component';
import TitlePage from '../components/TitlePage.component';

import QType from '../assets/q-2.png';
import q10 from '../assets/q-10.png';
import q11 from '../assets/q-11.png';
import q20 from '../assets/q-20.png';
import q21 from '../assets/q-21.png';

import XYZType from '../assets/xyz-2.png';
import x3 from '../assets/x3.png';
import x5 from '../assets/x5.png';


import CendanaType from '../assets/cendana-2.png';

import QImage from '../assets/q.png';
import XyzImage from '../assets/xyz.png';
import CendanaImage from '../assets/cendana.png';

import ProductUnitCard from '../components/ProductUnitCard.component';


import { faBed, faCouch, faBath, faCarTunnel, faStairs } from '@fortawesome/free-solid-svg-icons';

const ProductPage = ()=>{
    let [productType, setProductType] = useState('Q'); 

    const dataProduct = {
        Q: {
            productTitle: 'Q LIVIN',
            productImage: QImage,
            productType: ['Q10','Q11','Q20','Q21'],
            productDesc: 'Hunian terobosan terbaru yang menjadikan Courtyard sebagai pusat dari gaya hidup yang memberikan privasi ruang terbuka ditengah rumah penuh dengan cahaya alami dan udara segar. Serta mempunyai opsi bisa ditutup dengan skylight sehingga membuat rumah ini terlihat luas.',
            allUnit: [
                {
                    unitTitle: 'Q10',
                    unitImage: q10,
                    luasTanah:'25.4',
                    earlyPrice:'339 Juta',
                    marketPrice: '505 Juta',
                    luasBangunan: '36',
                    ukuran: '3 x 12',
                    unitDesc:'Rumah 1 lantai dengan konsep open space didalam rumah. menjadikan rumah memiliki privasi ruang terbuka dengan cahaya alami dengan 1 kamar tidur dan 1 kamar mandi.',
                    Facility:[
                        {
                            icon: faBed,
                            title:'1 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'1 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'1 Floor'
                        },
                    ]
                },
                {
                    unitTitle: 'Q11',
                    unitImage: q11,
                    luasTanah:'27.7',
                    luasBangunan: '39.6',
                    earlyPrice:'369 Juta',
                    marketPrice: '561 Juta',
                    ukuran: '3.3 x 12',
                    unitDesc:'Rumah 1 lantai dengan konsep open space didalam rumah. menjadikan rumah memiliki privasi ruang terbuka dengan cahaya alami dengan 1 kamar tidur dan 1 kamar mandi.',
                    Facility:[
                        {
                            icon: faBed,
                            title:'1 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'1 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'1 Floor'
                        },
                    ]
                },
                {
                    unitTitle: 'Q20',
                    unitImage: q20,
                    luasTanah:'42.9',
                    luasBangunan: '46.7',
                    earlyPrice:'485 Juta',
                    marketPrice: '703 Juta',
                    ukuran: '3.3 x 13',
                    unitDesc:'rumah 2 lantai dengan konsep open space didalam rumah menjadi rumah memiliki privasi ruang terbuka ditentah" rumah dengan cahaya alami. Tipe ini memiliki 1 kamar tidur dan 1 kamar mandi dilantai bawahnya, dan di lantai 2 merupakan kamar utama nya yang terdapat kamar mandi didalamnya.',
                    Facility:[
                        {
                            icon: faBed,
                            title:'2 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'2 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'2 Floor'
                        },
                    ]
                },
                {
                    unitTitle: 'Q21',
                    unitImage: q21,
                    luasTanah:'46.8',
                    luasBangunan: '50.5',
                    earlyPrice:'525 Juta',
                    marketPrice: '771 Juta',
                    ukuran: '3.6 x 13',
                    unitDesc:'Rumah 2 lantai dengan konsep open space didalam rumah menjadi rumah memiliki privasi ruang terbuka ditentah" rumah dengan cahaya alami. Tipe ini memiliki 1 kamar tidur dan 1 kamar mandi dilantai bawahnya, dan di lantai 2 merupakan kamar utama nya yang terdapat kamar mandi didalamnya.',
                    Facility:[
                        {
                            icon: faBed,
                            title:'2 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'2 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'2 Floor'
                        },
                    ]
                },
            ]
        },
        XYZ:{
            productTitle: 'XYZ Livin',
            productImage: XyzImage,
            productType: ['X3','X5'],
            productDesc: 'Hunian yang memiliki banyak tipe yaitu: X,Y,Z. Dengan spesifikasi 1 kamar, 2 kamar, dan 3 kamar. Masing masing tipe memiliki keunikannya masing". Didesain secara artistic & modern.',
            allUnit: [
                {
                    unitTitle: 'X3',
                    unitImage: x3,
                    luasTanah:'56.5',
                    earlyPrice:'636 Juta',
                    marketPrice: '912 Juta',
                    luasBangunan: '66.9',
                    ukuran: '5 x 11.3',
                    unitDesc:'Hunian yang memiliki banyak tipe yaitu: X,Y,Z. Dengan spesifikasi 1 kamar, 2 kamar, dan 3 kamar. Masing masing tipe memiliki keunikannya masing". Didesain secara artistic & modern.',
                    Facility:[
                        {
                            icon: faBed,
                            title:'3 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'2.5 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'2 Floor'
                        },
                    ]
                },
                {
                    unitTitle: 'x5',
                    unitImage: x5,
                    luasTanah:'70.6',
                    luasBangunan: '61.5',
                    earlyPrice:'669 Juta',
                    marketPrice: '971 Juta',
                    ukuran: '5 x 12.3',
                    unitDesc:'Hunian yang memiliki banyak tipe yaitu: X,Y,Z. Dengan spesifikasi 1 kamar, 2 kamar, dan 3 kamar. Masing masing tipe memiliki keunikannya masing". Didesain secara artistic & modern.',
                    Facility:[
                        {
                            icon: faBed,
                            title:'3 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'2.5 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'2 Floor'
                        },
                    ]
                },
            ]
        },
        CENDANA:{
            productTitle: 'CENDANA LIVIN',
            productImage: CendanaImage,
            productType: ['Tipe 1','Tipe 2+','Tipe 3+'],
            productDesc: 'Hunian yang memiliki 3 tipe rumah. Sehingga membuat pembeli memiliki opsi untuk memilih. Terdapat tipe 2 kamar dan 3 kamar yang luas sehingga membuat penghuni menjadi nyaman disertakan backyard dan balcon',
            allUnit: [
                {
                    unitTitle: 'Cendana Tipe 1',
                    unitImage: CendanaImage,
                    luasTanah:'60',
                    earlyPrice:'776 Juta',
                    marketPrice: '1.13 M',
                    luasBangunan: '55',
                    ukuran: '5 x 12',
                    unitDesc:'Hunian yang memiliki 3 tipe rumah. Sehingga membuat pembeli memiliki opsi untuk memilih. Terdapat tipe 2 kamar dan 3 kamar yang luas sehingga membuat penghuni menjadi nyaman disertakan backyard dan balcon',
                    Facility:[
                        {
                            icon: faBed,
                            title:'2 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'1.5 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'2 Floor'
                        },
                    ]
                },
                {
                    unitTitle: 'Cendana Tipe 2+',
                    unitImage: CendanaImage,
                    luasTanah:'82.5',
                    luasBangunan: '74.5',
                    earlyPrice:'999 Juta',
                    marketPrice: '1.42 M',
                    ukuran: '5.5 x 15',
                    unitDesc:'Hunian yang memiliki 3 tipe rumah. Sehingga membuat pembeli memiliki opsi untuk memilih. Terdapat tipe 2 kamar dan 3 kamar yang luas sehingga membuat penghuni menjadi nyaman disertakan backyard dan balcon',
                    Facility:[
                        {
                            icon: faBed,
                            title:'3 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'3 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'2 Floor'
                        },
                    ]
                },
                {
                    unitTitle: 'Cendana Tipe 3+',
                    unitImage: CendanaImage,
                    luasTanah:'97.5',
                    luasBangunan: '92.2',
                    earlyPrice:'1.65 M',
                    marketPrice: '1.15 M',
                    ukuran: '6.5 x 15',
                    unitDesc:'Hunian yang memiliki 3 tipe rumah. Sehingga membuat pembeli memiliki opsi untuk memilih. Terdapat tipe 2 kamar dan 3 kamar yang luas sehingga membuat penghuni menjadi nyaman disertakan backyard dan balcon',
                    Facility:[
                        {
                            icon: faBed,
                            title:'3 Bedroom'
                        },
                        {
                            icon: faBath,
                            title:'3 Bath'
                        },
                        {
                            icon: faStairs,
                            title:'2 Floor'
                        },
                    ]
                },
            ]
        },
    }

    return(
        <>
            <header className='primary-padding py-0 bg-primary-dark w-full h-[100vh] xl:px-8 md:h-full md:!px-0'>
                <div className='grid grid-cols-12 w-full h-full'>
                    <div className='col-span-12 h-full relative md:flex md:flex-wrap md:gap-8 md:justify-center md:p-4'>
                        <img src={QType} alt="" className='object-cover w-60 h-60 absolute top-8 left-8 z-30 border border-secondary-gold xl:h-52 xl:w-52 lg:left-auto lg:right-24 lg:top-4 md:static md:order-1'/>
                        <img src={XYZType} alt="" className='object-cover w-60 h-60 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 border border-secondary-gold xl:h-52 xl:w-52 lg:left-auto lg:right-24 lg:-translate-x-0 lg:top-[50%] lg:bottom-60 md:!transform-none md:static md:order-1'/>
                        <img src={CendanaType} alt="" className='object-cover w-60 h-60 absolute bottom-8 right-8 z-30 border border-secondary-gold xl:h-52 xl:w-52 lg:right-24 lg:bottom-4 md:static md:order-1'/>
                        <div className='w-1/2 h-1/2  absolute bottom-16 left-0 flex flex-col justify-end p-8 z-20 lg:bottom-1/4 md:static md:w-auto md:h-auto md:justify-start md:text-center'>
                            <h1 className='text-primary-gold font-bold text-[6rem] mb-3 xl:text-[4rem]'>Tipe Unit</h1>
                            <p className='leading text-primary-light text-lg opacity-80'>Seperti project lippo homes sebelumnya salah satunya Cendana homes series, Park Serpong juga memiliki beberapa pilihan type unit dengan harga 339 jutaan per unit.</p>
                        </div>
                        <div className='w-1/2 h-1/2 absolute top-16 right-0 opacity-60 border border-secondary-gold md:hidden' style={{ backgroundImage: `url(${QImage})` }}>
                        </div>
                        <div className='w-1/2 h-1/2 absolute top-0 left-24 opacity-70 border border-secondary-gold lg:right-0 lg:left-auto md:hidden' style={{ backgroundImage: `url(${XyzImage})` }}>
                        </div>
                        <div className='w-1/2 h-1/2 absolute bottom-16 right-0 opacity-40 border border-secondary-gold md:hidden' style={{ backgroundImage: `url(${CendanaImage})` }}>
                        </div>
                    </div>
                </div>
            </header>
            <section className=" bg-primary-gray primary-padding">
                {/* navigantion product content */}
                <div className="col-span-12 w-full h-full border border-primary-gold">
                    <div className="flex flex-row" onChange={(event)=>{
                        setProductType(event.target.value);
                    }}>
                        <label htmlFor="q" className={`flex-1 cursor-pointer p-2  font-bold text-center ${productType == 'Q' ? 'bg-primary-gold text-primary-light' : 'bg-primary-dark text-primary-gold opacity-80'}`}>
                            <input type="radio" id="q" value="Q" defaultChecked name="productType" className="hidden checked:text-primary-light" />
                            Q LIVIN
                        </label>
                        <label htmlFor="xyz" className={`flex-1 cursor-pointer border-x border-primary-gold p-2  font-bold text-center ${productType == 'XYZ' ? 'bg-primary-gold text-primary-light' : 'bg-primary-dark text-primary-gold opacity-80'}`}>
                            <input type="radio" id="xyz" value="XYZ" name="productType" className="hidden" />
                            XYZ LIVIN
                        </label>
                        <label htmlFor="cendana" className={`flex-1 cursor-pointer p-2  font-bold text-center ${productType == 'CENDANA' ? 'bg-primary-gold text-primary-light' : 'bg-primary-dark text-primary-gold opacity-80'}`}>
                            <input type="radio" id="cendana" value="CENDANA" name="productType" className="hidden"/>
                            CENDANA
                        </label>
                    </div>
                </div>

                {/* main product content */}
                <div className="py-4 mb-4">
                    <SectionImage src={dataProduct[productType].productImage} heightImage='h-[300px] object-cover' opacity='opacity-70'>
                    <div className="absolute inset-0 m-auto flex justify-center items-center">
                        <h6 className="text-[6rem] font-bold text-primary-light z-20 uppercase text-center md:text-[4rem] sm:!text-[2rem]">{dataProduct[productType].productTitle}</h6>
                    </div>
                    </SectionImage>
                </div>
                <div className='grid grid-cols-12'>
                    <div className='col-span-8 p-4 md:col-span-12'>
                        <TitlePage title={`WHY ${ dataProduct[productType].productTitle} ?`} position='start'/>
                        <p className='leading-relaxed text-lg text-secondary-dark'>{dataProduct[productType].productDesc}</p>
                    </div>
                    <div className='col-span-4 p-4 md:col-span-12'>
                        <TitlePage title={`Tipe Rumah ${dataProduct[productType].productTitle}`} optClass={'xl:text-3xl'} position='start'/>
                        <ul className='p-4 grid grid-cols-2 text-2xl font-bold text-primary-gold'>
                        {dataProduct[productType].productType.map((item, i) => (<span key={i} className="text-secondary-dark flex items-center font-semibold before:contents-['*'] before:block before:w-2 before:h-2 before:rounded-full before:bg-secondary-gold before:mr-2 xl:text-lg">{item}</span>))}
                        </ul>
                    </div>
                </div>
                <div className='w-full h-full flex flex-col gap-8'>
                    {dataProduct[productType].allUnit.map((data, i)=><ProductUnitCard data={data} index={i} key={i}/>)}
                </div>
            </section>       
        </>
    )
}

export default ProductPage;
