import { NavLink } from "react-router-dom";
import imageLogo from '../assets/logo.png';

const Navbar = ()=> {
    return (
    <nav className="grid grid-cols-2 primary-padding bg-primary-dark text-primary-light">
        <div className="sm:col-span-2 sm:mb-4 sm:flex sm:justify-center">
            <NavLink>
                <img src={imageLogo} alt="" className="w-48"  />
            </NavLink>
        </div>
        <ul className="flex justify-between items-center sm:col-span-2">
            <li>
                <NavLink to="/"   className={({ isActive }) =>
                isActive ? 'text-secondary-gold' : ''}>Home</NavLink>
            </li>
            <li>
                <NavLink to="/products" className={({ isActive }) =>
                isActive ? 'text-secondary-gold' : ''}>Products</NavLink>
            </li>
            <li>
                <a href="#about">About</a>
            </li>
            <li>
                <a href="#contact">Contacts</a>
            </li>
        </ul>
    </nav>
    );
}

export default Navbar;