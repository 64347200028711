import ProductCard from './ProductCard.component';

import qImage from '../assets/q.png';
import xyzImage from '../assets/xyz.png';
import cendanaImage from '../assets/cendana.png';


const Product = ()=>{
    const products = [
        {
            image: qImage,
            productTitle: 'Q Livin',
            productDesc: 'Rumah baru Park Serpong Q Livin menawarkan courtyard sebagai pusat dari gaya hidup yang memberikan privasi ruang terbuka ditengah rumah penuh dengan cahaya alami dan udara segar. Opsi skylight dalam rumah yang dapat ditutup memberikan kesan ruangan rumah lebih luas.',
            earlyPrice: '399 Juta',
            marketPrice: '505 Juta',
            productType: ['Q10','Q11','Q20','Q21'],
        },
        {
            image: xyzImage,
            productTitle: 'XYZ Livin',
            productDesc: 'Rumah Park Serpong XYZ Livin menawarkan hunian rumah baru artistik desain modern. Inovasi tata ruangan rumah yang optimal, sleek dan stylish yang cocok bagi mereka yang baru ingin membeli rumah pertama.',
            earlyPrice: '636 Juta',
            marketPrice: '912 Juta',
            productType: ['X3','X5'],
        },
        {
            image: cendanaImage,
            productTitle: 'Cendana Livin',
            productDesc: 'Cendana Livin adalah satu dari 2 cluster yang diluncurkan perdana di kawasan Cityzen Park, Park Serpong. Rumah dirancang dengan gaya modern dan stylish yang mengikuti tren terbaru dan memiliki estetika yang kontemporer.',
            earlyPrice: '776 Juta',
            marketPrice: '1,13 M',
            productType: ['Tipe 1','Tipe 2+','Tipe 3+'],
        },

    ]


    return (
        <section className="bg-gradient-to-b from-primary-gray from-0% via-primary-gray via-35% to-primary-light to-35% w-full py-8">
            <div className="primary-padding grid grid-cols-3 gap-16 lg:gap-8 md:grid-cols-1">
                {products.map((data,i) => <ProductCard data={data} key={i}/>)}
            </div>
        </section>        
    );
}

export default Product;