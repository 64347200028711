import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BenenfitCard = ({icon, title, desc})=>{
    return(
        <div className="bg-primary-gray flex flex-col justify-center items-center text-center rounded-md border border-primary-gold h-52 relative">
            <FontAwesomeIcon icon={icon} className='bg-primary-dark absolute top-[-30px] left-0 right-0 m-auto text-4xl p-4 rounded-full text-primary-light'/>
            <h6 className='text-primary-gold text-xl font-bold mb-4'>
                {title}
            </h6>
            <p className='leading text-secondary-dark font-semibold text-sm w-3/4'>
                {desc}
            </p>
        </div>
    );
}

export default BenenfitCard;