import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";


const LocationIcon = ({time, location})=>{
    return (
        <div className="flex items-center">
            <FontAwesomeIcon icon={faMapPin} className="text-secondary-gold text-5xl"/>
            <div className="flex-1 ml-3">
                <span className="font-bold text-secondary-gray text-xl">{time}</span>
                <span className="font-bold text-secondary-gold text-md block">{location}</span>
            </div>
        </div>
    )
}

export default LocationIcon;