import WhatsappButton from './buttons/WhatsappButton.component';

const Contact = ()=>{
    return(
        <section className="bg-primary-light primary-padding flex items-center py-8 md:flex-col md:text-center" id="contact">
            <div className="flex-1 text-4xl font-bold text-secondary-dark tracking-widest leading-snug lg:text-3xl md:mb-4 sm:!text-2xl">
                <span>Apa lagi yang ditunggu?</span>
                <span className="block">Kontak kami sekarang</span>
            </div>
            <div className="flex-1">
                <p className="text-xl text-secondary-dark mb-3 font-semibold sm:!text-lg">Anda bisa menghubungi kami melalui kontak atau tombol berikut</p>
                <div className='flex justify-between items-center sm:flex-col sm:gap-8'>
                    <div className='flex-1'>
                        <WhatsappButton/>
                    </div>
                    <div className='flex-1'>
                        <span className='font-bold text-secondary-dark text-2xl'>+6281286163385 (WinProperty)</span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;